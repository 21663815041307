<template>
  <div>
    <v-row>
      <v-col sm="12" md="12" lg="12" class="pa-10 pb-5">
        <v-card elevation="1" class="element">
          <v-card-title> </v-card-title>
          <v-card-text class="text-center">
            <v-row justify="center">
              <v-col sm="12" md="12" lg="12">
                <h1 class="pa-4">Oops!</h1>
                <h4>Access Denied.</h4>
                <v-img
                  :lazy-src="url"
                  class="img-404"
                  max-width="300"
                  :src="url"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12" class="justify-center pt-10">
                <v-btn
                  class="btn-style"
                  outlined
                  elevation="1"
                  color="primarytext"
                  @click="goToHome()"
                >
                  <v-icon color="secondary" left>mdi-home</v-icon> Return Home
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  computed: {
    url() {
      return this.$vuetify.theme.dark
        ? require('@/assets/images/Unauthorized.png')
        : require('@/assets/images/Unauthorized.png');
    },
  },
  methods: {
    goToHome() {
      this.$router.push('/home');
    },
  },
};
</script>
<style>
.element {
  height: 80vh;
}
.img-404 {
  margin: auto;
}
</style>